import { Box, Container, Toolbar, } from "@mui/material";
import { FC, ReactNode } from "react";
import NavDrawer from "../../components/NavDrawer/NavDrawer";
import NavDrawerMobile from "../../components/NavDrawer/NavDrawerMobile";
import useBreakpoint from "../../utils/useBreakPoint";
import AdminAppBar from "../../components/AppBar/AdminAppBar";

export type AdminPageBaseProps = {
  pageTitle: string;
  children: ReactNode;
};

const AdminPageBase: FC<AdminPageBaseProps> = ({ pageTitle, children }) => {
  const { innerWidth, breakpoint } = useBreakpoint();
  
  const desktopSizeWidgets = <Box sx={{ display: 'flex' }}>
    <AdminAppBar title={pageTitle} />
    <NavDrawer />
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth='xl'>
        <Toolbar />
        <Box my={1} />
        {children}
      </Container>
    </Box>
  </Box>;
  
  const tabletSizeWidgets = <Box>
    <AdminAppBar title={pageTitle} />
    <NavDrawerMobile />
    <Box component="main" maxWidth='100%'>
      <Container maxWidth='xl'>
        <Toolbar />
        <Box my={2} />
        {children}
      </Container>
    </Box>
  </Box>;
  
  switch (breakpoint) {
    // TODO: Mobile
    case "xs":
    case "sm":
      // Tablet size
      return tabletSizeWidgets;
    case "md":
    case "lg":
    case "xl":
      return desktopSizeWidgets;
    default:
      return desktopSizeWidgets;
  }
}

export default AdminPageBase;