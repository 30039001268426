import { Chip, Typography } from "@mui/material";
import { FC } from "react";
import { Variant } from "@mui/material/styles/createTypography";
import { ClinicStatus, ClinicStatusStr } from "../../types/ClinicStatus";

type ClinicStatusChipProps = {
  clinicStatus: ClinicStatus;
  fontSize?: Variant;
}

const ClinicStatusChip: FC<ClinicStatusChipProps> = ({ clinicStatus, fontSize }) => {
  let str: ClinicStatusStr;
  let color;
  switch (clinicStatus) {
    case 1:
      str = "未設定";
      color = "#FFFACD";
      break;
    case 2:
      str = "設定済";
      color = "#D6ECDC";
      break;
    case 9:
      str = "削除済";
      color = "#FC7D7D";
      break;
    default:
      str = "未設定";
      color = "#FFFACD";
      break;
  }
  
  return <Chip label={<Typography variant={fontSize}>
    {str}
  </Typography>} sx={{ px: "4px", backgroundColor: color }} />;
};

ClinicStatusChip.defaultProps = {
  fontSize: "subtitle1"
};


export default ClinicStatusChip;