import {
  setDoc,
  doc,
  collection,
  query,
  where,
  orderBy,
  QuerySnapshot,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import IClinicInformation, { clinicInformationConverter } from "../interfaces/IClinicInformation";
import { firestoreSecondary } from "../firebase";
import { IClinicAccount } from "../interfaces/IClinicAccount";
import { ClinicStatus } from "../types/ClinicStatus";

/**
 * 新規クリニックアカウントの作成
 * @param clinicAccount クリニックアカウント情報
 * @param clinicInfo クリニック詳細情報
 * @param uid クリニックUID
 */
export const createClinicInformation = async (clinicAccount: IClinicAccount,
  clinicInfo: IClinicInformation, uid: string) => {
  const apiKeysDocRef = doc(firestoreSecondary, "clinics", uid, "api_keys", uid);
  await setDoc(apiKeysDocRef, {
    "line_messaging_channel_access_token": clinicAccount.lineMessagingChannelAccessToken,
    "line_messaging_channel_secret": clinicAccount.lineMessagingChannelSecret,
    "line_auth_channel_id": clinicAccount.lineAuthChannelId,
    "line_auth_client_secret": clinicAccount.lineAuthClientSecret
  });
  const docRef = doc(firestoreSecondary, "clinics", uid).withConverter(clinicInformationConverter);
  await setDoc(docRef, clinicInfo);
};

/**
 * すべてのクリニック基本情報をStreamに取得する。
 */
export const onSnapshotClinics = (onChange: (snapshot: QuerySnapshot<IClinicInformation>) => void) => {
  const clinicsCol = collection(firestoreSecondary, "clinics").withConverter(clinicInformationConverter);
  const q = query(clinicsCol, orderBy("createdAt"));
  const sub = onSnapshot(q, (snapshot) => {
    onChange(snapshot);
  });
  return sub;
};

/**
 * クリニックステータスの変更。
 * @param clinicId クリニックID
 * @param nextStatus 次のクリニックステータス
 */
export const updateClinicStatus = async (clinicId: string, nextStatus: ClinicStatus) => {
  if (clinicId === "") {
    return;
  }
  const clinicDocRef = doc(firestoreSecondary, "clinics", clinicId).withConverter(clinicInformationConverter);
  await updateDoc(clinicDocRef, {
    clinicStatus: nextStatus
  });
};