import { useRecoilState, useResetRecoilState } from "recoil";
import { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useAuthSendPasswordResetEmail } from "@react-query-firebase/auth";
import resetPasswordPageStateAtom from "../../recoil/features/ResetPasswordPage";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { IResetPassword, validateResetPassword } from "../../interfaces/IAuthInformation";
import { auth } from "../../firebase";
import loginPageStateAtom from "../../recoil/features/LoginPage";

const useResetPasswordState = () => {
  const [ state, setState ] = useRecoilState(resetPasswordPageStateAtom);
  const resetState = useResetRecoilState(loginPageStateAtom);
  const { openAlert } = useResultAlertState();
  useEffect(() => () => resetState(), [ resetState ]);
  
  const onChangeEmail = useCallback((email: string) => {
    setState((prev) => ({ ...prev, email }));
  }, [ setState ]);
  
  const resetPasswordMutation = useAuthSendPasswordResetEmail(auth);
  
  const sendResetPasswordEmail = useCallback(async () => {
    const resetPasswordItem: IResetPassword = {
      email: state.email
    };
    const errors = validateResetPassword(resetPasswordItem);
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      openAlert("error", "登録時に使用したメールアドレスを入力してください。");
      return;
    }
    setState((prev) => ({ ...prev, errors: undefined }));
    await resetPasswordMutation.mutateAsync({
      email: resetPasswordItem.email
    });
  }, [ openAlert, resetPasswordMutation, setState, state.email ]);
  
  const {
    error: passwordResetError,
    isLoading: isLoadingSendResetPasswordEmail,
    isRefetching: isRefetchingSendResetPasswordEmail,
    refetch
  } = useQuery("sendResetPasswordEmail", () => sendResetPasswordEmail(), {
    enabled: false,
    suspense: false
  });
  
  const onClickSendResetPasswordEmail = useCallback(() => {
    void refetch();
  }, [ refetch ]);
  
  useEffect(() => {
    if (passwordResetError) {
      openAlert("error", "パスワードリセットのメール送信に失敗しました。");
    }
  }, [ passwordResetError, openAlert ]);
  
  const isLoading = useMemo(
    () => isLoadingSendResetPasswordEmail || isRefetchingSendResetPasswordEmail,
    [ isLoadingSendResetPasswordEmail, isRefetchingSendResetPasswordEmail ]
  );
  
  return { state, onChangeEmail, onClickSendResetPasswordEmail, isLoading };
};

export default useResetPasswordState;