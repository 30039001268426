import { useRecoilState, useResetRecoilState } from "recoil";
import { useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import { useAuthCreateUserWithEmailAndPassword } from "@react-query-firebase/auth";
import registerClinicPageAtom from "../../recoil/features/RegisterClinicPage";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { IClinicAccount, validateRegisterClinic } from "../../interfaces/IClinicAccount";
import { authSecondary } from "../../firebase";
import IClinicInformation from "../../interfaces/IClinicInformation";
import { createClinicInformation } from "../../repositories/clinicRepository";

const useRegisterClinicPageState = () => {
  const [ state, setState ] = useRecoilState(registerClinicPageAtom);
  const resetState = useResetRecoilState(registerClinicPageAtom);
  useEffect(() => () => resetState(), [ resetState ]);
  const { openAlert } = useResultAlertState();
  
  const onChangeEmail = useCallback((email: string) => {
    setState((prev) => ({ ...prev, email }));
  }, [ setState ]);
  
  const onChangePassword = useCallback((password: string) => {
    setState((prev) => ({ ...prev, password }));
  }, [ setState ]);
  
  const onChangeConfirmPassword = useCallback((confirmPassword: string) => {
    setState((prev) => ({ ...prev, confirmPassword }));
  }, [ setState ]);
  
  const onChangeLineMessagingChannelAccessToken = useCallback((lineMessagingChannelAccessToken: string) => {
    setState((prev) => ({ ...prev, lineMessagingChannelAccessToken }));
  }, [ setState ]);
  
  const onChangeLineMessagingChannelSecret = useCallback((lineMessagingChannelSecret: string) => {
    setState((prev) => ({ ...prev, lineMessagingChannelSecret }));
  }, [ setState ]);
  
  const onChangeLineAuthChannelId = useCallback((lineAuthChannelId: string) => {
    setState((prev) => ({ ...prev, lineAuthChannelId }));
  }, [ setState ]);
  
  const onChangeLineAuthClientSecret = useCallback((lineAuthClientSecret: string) => {
    setState((prev) => ({ ...prev, lineAuthClientSecret }));
  }, [ setState ]);
  
  const signUpMutation = useAuthCreateUserWithEmailAndPassword(authSecondary);
  
  const registerClinic = useCallback(async () => {
    const clinicAccount: IClinicAccount = {
      email: state.email,
      password: state.password,
      confirmPassword: state.confirmPassword,
      lineMessagingChannelAccessToken: state.lineMessagingChannelAccessToken,
      lineMessagingChannelSecret: state.lineMessagingChannelSecret,
      lineAuthChannelId: state.lineAuthChannelId,
      lineAuthClientSecret: state.lineAuthClientSecret
    };
    const clinicInfo: IClinicInformation = {
      id: "",
      clinicName: "",
      postalCode: "",
      address: "",
      phoneNumber: "",
      departments: "",
      freeInputs: "",
      reservationPeriod: "",
      isAvailableSameDayReserve: true,
      sameDayReservePeriod: "30",
      calendarStart: 0,
      email: state.email,
      createdAt: new Date().getTime(),
      clinicStatus: 1,
      isInitialized: false
    };
    const errors = validateRegisterClinic(clinicAccount);
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));
    const res = await signUpMutation.mutateAsync({
      email: state.email,
      password: state.password
    });
    if (!res.user) {
      throw new Error("クリニックアカウントの登録に失敗しました。");
    }
    clinicInfo.id = res.user.uid;
    await createClinicInformation(clinicAccount, clinicInfo, res.user.uid);
  }, [ setState, signUpMutation, state.confirmPassword, state.email, state.lineAuthChannelId, state.lineAuthClientSecret, state.lineMessagingChannelAccessToken, state.lineMessagingChannelSecret, state.password ]);
  
  const registerClinicMutate = useMutation<void, Error>(
    () => registerClinic(),
    {
      onSuccess: () => {
        openAlert("success", "クリニックアカウントの作成に成功しました。");
        resetState();
      },
      onError: (error) => {
        openAlert("error", error.message);
      }
    }
  );
  
  const onClickRegisterClinic = useCallback(() => {
    void registerClinicMutate.mutate();
  }, [ registerClinicMutate ]);
  
  return {
    state,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeLineMessagingChannelAccessToken,
    onChangeLineMessagingChannelSecret,
    onChangeLineAuthChannelId,
    onChangeLineAuthClientSecret,
    onClickRegisterClinic,
    isLoading: registerClinicMutate.isLoading
  };
};

export default useRegisterClinicPageState;