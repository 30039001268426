import { useQuery } from "react-query";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useCallback, useEffect, useMemo } from "react";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import { useNavigate } from "react-router";
import loginPageStateAtom from "../../recoil/features/LoginPage";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { ISignIn, validateSignIn } from "../../interfaces/IAuthInformation";
import { auth } from "../../firebase";

const useLoginPageState = () => {
  const navigate = useNavigate();
  const [ state, setState ] = useRecoilState(loginPageStateAtom);
  const resetState = useResetRecoilState(loginPageStateAtom);
  const { openAlert } = useResultAlertState();
  useEffect(() => () => resetState(), [ resetState ]);
  
  const onChangeEmail = useCallback((email: string) => {
    setState((prev) => ({ ...prev, email }));
  }, [ setState ]);
  
  const onChangePassword = useCallback((password: string) => {
    setState((prev) => ({ ...prev, password }));
  }, [ setState ]);
  
  const loginMutation = useAuthSignInWithEmailAndPassword(auth);
  
  const login = useCallback(async () => {
    const signInItem: ISignIn = {
      email: state.email,
      password: state.password
    };
    const errors = validateSignIn(signInItem);
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      return undefined;
    }
    setState((prev) => ({ ...prev, errors: undefined }));
    const res = await loginMutation.mutateAsync(signInItem);
    return res.user;
  }, [ loginMutation, setState, state.email, state.password ]);
  
  const {
    data: loginResult,
    error: loginError,
    isLoading: isLoadingLogin,
    isRefetching: isRefetchingLogin,
    refetch
  } = useQuery("Login", login, { enabled: false, suspense: false });
  
  const onClickLogin = useCallback(() => {
    void refetch();
  }, [ refetch ]);
  
  useEffect(() => {
    if (loginError) {
      console.log(loginError);
      openAlert("error", "ログインに失敗しました。");
    }
    if (loginResult) {
      openAlert("success", "ログインに成功しました。");
      navigate("/");
    }
  }, [ loginError, loginResult, navigate, openAlert ]);
  
  const isLoading = useMemo(() => isLoadingLogin || isRefetchingLogin, [ isLoadingLogin, isRefetchingLogin ]);
  
  return { state, onChangeEmail, onChangePassword, onClickLogin, isLoading };
};

export default useLoginPageState;