import { atom } from "recoil";
import { SignInErrors } from "../../../interfaces/IAuthInformation";

export type LoginPageState = {
  email: string;
  password: string;
  errors: SignInErrors | undefined;
};

const initialState: LoginPageState = {
  email: "",
  password: "",
  errors: undefined
};

const loginPageStateAtom = atom({
  key: "LoginPageState",
  default: initialState
});

export default loginPageStateAtom;