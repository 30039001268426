import { atom } from "recoil";
import { RegisterClinicErrors } from "../../../interfaces/IClinicAccount";

export type RegisterClinicPageState = {
  email: string;
  password: string;
  confirmPassword: string;
  lineMessagingChannelAccessToken: string;
  lineMessagingChannelSecret: string;
  lineAuthChannelId: string;
  lineAuthClientSecret: string;
  errors: RegisterClinicErrors | undefined;
}

const initialState: RegisterClinicPageState = {
  email: "",
  password: "",
  confirmPassword: "",
  lineMessagingChannelAccessToken: "",
  lineMessagingChannelSecret: "",
  lineAuthChannelId: "",
  lineAuthClientSecret: "",
  errors: undefined
};

const registerClinicPageAtom = atom({
  key: "RegisterClinicPageState",
  default: initialState
});

export default registerClinicPageAtom;