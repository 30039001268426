import { validateEmail, validatePassword } from "../utils/validator";

export type IClinicAccount = {
  email: string;
  password: string;
  confirmPassword: string;
  lineMessagingChannelAccessToken: string;
  lineMessagingChannelSecret: string;
  lineAuthChannelId: string;
  lineAuthClientSecret: string;
};
export type RegisterClinicErrors = {
  email: string | undefined,
  password: string | undefined,
  confirmPassword: string | undefined,
  lineMessagingChannelAccessToken: string | undefined,
  lineMessagingChannelSecret: string | undefined,
  lineAuthChannelId: string | undefined,
  lineAuthClientSecret: string | undefined,
}
export const validateRegisterClinic = (clinicAccount: IClinicAccount): RegisterClinicErrors | undefined => {
  const errors: RegisterClinicErrors = {
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    lineMessagingChannelAccessToken: undefined,
    lineMessagingChannelSecret: undefined,
    lineAuthChannelId: undefined,
    lineAuthClientSecret: undefined
  };
  errors.email = validateEmail(clinicAccount.email);
  errors.password = validatePassword(clinicAccount.password);
  if (clinicAccount.password !== clinicAccount.confirmPassword) {
    errors.confirmPassword = "確認用パスワードが正しくありません。";
  }
  // errors.lineMessagingChannelAccessToken = validateRequired(clinicAccount.lineMessagingChannelAccessToken);
  // errors.lineMessagingChannelSecret = validateRequired(clinicAccount.lineMessagingChannelSecret);
  // errors.lineAuthChannelId = validateRequired(clinicAccount.lineAuthChannelId);
  // errors.lineAuthClientSecret = validateRequired(clinicAccount.lineAuthClientSecret);
  
  if (Object.values(errors).findIndex((element) => typeof element === "string") !== -1) {
    return errors;
  }
  return undefined;
};