import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// AdminシステムのFirebase環境につなぐ
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_ADMIN,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_ADMIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_ADMIN,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_ADMIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_ADMIN,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_ADMIN
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, "asia-northeast1");


// クリニック・病院の診察予約サービスのFirebase環境につなぐ（secondary）
const secondaryAppConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const secondary = initializeApp(secondaryAppConfig, "secondary");

export const firestoreSecondary = getFirestore(secondary);
export const authSecondary = getAuth(secondary);
export const functionsSecondary = getFunctions(secondary, "asia-northeast1");