import { AlertColor } from "@mui/material";
import { atom } from "recoil";

export type ResultAlertState = {
  isOpen: boolean;
  result: AlertColor;
  message: string;
};

const initialState: ResultAlertState = {
  isOpen: false,
  result: 'success',
  message: '',
};

const resultAlertStateAtom = atom({
  key: 'ResultAlertState',
  default: initialState,
});

export default resultAlertStateAtom;