import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useMutation } from "react-query";
import homePageStateAtom from "../../recoil/features/HomePage";
import { onSnapshotClinics, updateClinicStatus } from "../../repositories/clinicRepository";
import { ClinicStatus } from "../../types/ClinicStatus";
import IClinicInformation from "../../interfaces/IClinicInformation";

const useHomePageState = () => {
  const [ state, setState ] = useRecoilState(homePageStateAtom);
  
  // const onClickSearch = useCallback((searchEmailText: string, searchClinicNameText: string) => {
  //   setState((prev) => ({ ...prev, searchEmailText, searchClinicNameText }));
  // }, [ setState ]);
  
  useEffect(() => {
    const sub = onSnapshotClinics( (snapshot) => {
      if (snapshot.docs.length > 0) {
        const res = snapshot.docs.map((value) => value.data());
        setState((prev) => ({
          ...prev,
          clinics: res
        }));
      } else {
        setState((prev) => ({
          ...prev,
          clinics: []
        }));
      }
    });
    
    return () => {
      sub();
    };
  }, [ setState ]);
  
  const updateClinicStatusMutate = useMutation(
    async (val: { reservationId: string, status: ClinicStatus }) => updateClinicStatus(val.reservationId, val.status),
    {
      onSuccess: () => {
        console.log("onSuccess update reserve status");
      }
    }
  );
  
  const onClickUpdateClinicStatus = useCallback((clinicInfo: IClinicInformation, nextStatus: ClinicStatus) => {
    void updateClinicStatusMutate.mutate({ reservationId: clinicInfo.id, status: nextStatus });
  }, [ updateClinicStatusMutate ]);
  
  
  return { state, onClickUpdateClinicStatus };
};

export default useHomePageState;