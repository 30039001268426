import { atom } from "recoil";
import IClinicInformation from "../../../interfaces/IClinicInformation";

export type HomePageState = {
  clinics: IClinicInformation[];
  searchEmailText: string;
  searchClinicNameText: string;
};

const initialState: HomePageState = {
  clinics: [],
  searchEmailText: "",
  searchClinicNameText: ""
};

const homePageStateAtom = atom({
  key: "HomePageState",
  default: initialState
});

export default homePageStateAtom;