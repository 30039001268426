import { useRecoilState } from "recoil";
import { useCallback } from "react";
import clinicDetailDialogStateAtom from "../../../recoil/features/HomePage/ClinicDetailDialog";
import IClinicInformation from "../../../interfaces/IClinicInformation";

const useClinicDetailDialogState = () => {
  const [ state, setState ] = useRecoilState(clinicDetailDialogStateAtom);
  
  const openDialog = useCallback((clinicInfo: IClinicInformation) => {
    setState((prev) => ({ ...prev, isOpen: true, clinicInfo }));
  }, [ setState ]);
  
  const closeDialog = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false, clinicInfo: undefined }));
  }, [ setState ]);
  
  return {
    state,
    openDialog,
    closeDialog
  };
};

export default useClinicDetailDialogState;