import { Container, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import InputForm from "../../components/InputForm/InputForm";
import useRegisterClinicPageState from "./useRegisterClinicPageState";
import AdminPageBase from "../PageBase/AdminPageBase";

const RegisterClinicPage = () => {
  const {
    state,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeLineMessagingChannelAccessToken,
    onChangeLineMessagingChannelSecret,
    onChangeLineAuthChannelId,
    onChangeLineAuthClientSecret,
    onClickRegisterClinic,
    isLoading
  } = useRegisterClinicPageState();
  
  return <AdminPageBase pageTitle="クリニック登録">
    <Container maxWidth="sm">
      <Stack direction="column" justifyContent="center" alignItems="center" py={2} spacing={4}>
        <Typography variant="h5">アカウント発行</Typography>
        <InputForm label="メールアドレス" required type="email"
                   onChange={onChangeEmail}
                   value={state.email}
                   error={state.errors?.email}
        />
        <InputForm label="パスワード" required password
                   onChange={onChangePassword}
                   value={state.password}
                   error={state.errors?.password}
        />
        <InputForm label="確認パスワード" required password
                   onChange={onChangeConfirmPassword}
                   value={state.confirmPassword}
                   error={state.errors?.confirmPassword}
        />
        <Typography variant="subtitle2">
          下記入力欄は、LINE連携機能の利用に必要なデータとなります。<br />
          どれか１つでも未入力や間違いがある場合、LINE連携機能が正しく動作しなくなります。
        </Typography>
        <InputForm label="LINE Messaging API チャネルアクセストークン（長期） "
                   onChange={onChangeLineMessagingChannelAccessToken}
                   value={state.lineMessagingChannelAccessToken}
                   error={state.errors?.lineMessagingChannelAccessToken}
        />
        <InputForm label="LINE Messaging API チャネルシークレット"
                   onChange={onChangeLineMessagingChannelSecret}
                   value={state.lineMessagingChannelSecret}
                   error={state.errors?.lineMessagingChannelSecret}
        />
        <InputForm label="LINEログインAPI チャネルID"
                   onChange={onChangeLineAuthChannelId}
                   value={state.lineAuthChannelId}
                   error={state.errors?.lineAuthChannelId}
        />
        <InputForm label="LINEログインAPI チャネルシークレット"
                   onChange={onChangeLineAuthClientSecret}
                   value={state.lineAuthClientSecret}
                   error={state.errors?.lineAuthClientSecret}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{
            borderRadius: "24px",
            color: "#FFFFFF",
            elevation: 3,
            py: "8px"
          }}
          loadingIndicator="実行中..."
          loading={isLoading}
          onClick={onClickRegisterClinic}
        >
          アカウントを作成する
        </LoadingButton>
      </Stack>
    </Container>
  </AdminPageBase>;
};

export default RegisterClinicPage;