import { Button, Container, Stack, Typography } from "@mui/material";
import { useState } from "react";
import useHomePageState from "./useHomePageState";
import ClinicListItem from "./ClinicListItem";
import InputForm from "../../components/InputForm/InputForm";
import AdminPageBase from "../PageBase/AdminPageBase";
import ClinicDetailDialog from "./ClinicDetailDialog/ClinicDetailDialog";

const HomePage = () => {
  const { state } = useHomePageState();
  // const [ tmpEmailInput, setTmpEmailInput ] = useState("");
  // const [ tmpClinicNameInput, setTmpClinicNameInput ] = useState("");
  
  return <AdminPageBase pageTitle="ホーム">
    <Container maxWidth="sm">
      <Stack direction="column" justifyContent="center" alignItems="center" py={2} spacing={4}>
        <Typography variant="h5">クリニック一覧</Typography>
        {/* <Stack direction="row" justifyContent="center" spacing={2}> */}
        {/*  <InputForm label="メールアドレス" value={tmpEmailInput} onChange={(value) => setTmpEmailInput(value)} /> */}
        {/*  <InputForm label="クリニック名" value={tmpClinicNameInput} onChange={(value) => setTmpClinicNameInput(value)} /> */}
        {/*  <Button variant="contained" onClick={() => onClickSearch(tmpEmailInput, tmpClinicNameInput)}> */}
        {/*    検索 */}
        {/*  </Button> */}
        {/* </Stack> */}
        <Stack direction="column" justifyContent="center" spacing={2}>
          {state.clinics.length > 0
            ? state.clinics.map((clinic, index) =>
              <ClinicListItem key={clinic.id} clinicInfo={clinic} index={index + 1} />)
            : <Typography>クリニックアカウントがありません。</Typography>
          }
        </Stack>
      </Stack>
    </Container>
    <ClinicDetailDialog />
  </AdminPageBase>;
};

export default HomePage;