import { FirestoreDataConverter, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { ClinicStatus } from "../types/ClinicStatus";

export default interface IClinicInformation {
  id: string,
  clinicName: string,
  postalCode: string,
  address: string,
  phoneNumber: string,
  departments: string,
  freeInputs: string,
  reservationPeriod: string,
  isAvailableSameDayReserve: boolean,
  sameDayReservePeriod: string,
  calendarStart: number,
  email: string,
  createdAt: number,
  clinicStatus: ClinicStatus,
  isInitialized: boolean,
}

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、クリニック基本情報の型を変換する。
 */
export const clinicInformationConverter: FirestoreDataConverter<IClinicInformation> = {
  toFirestore: (clinicInfo: IClinicInformation): DocumentData => ({
    clinicName: clinicInfo.clinicName,
    postalCode: clinicInfo.postalCode,
    address: clinicInfo.address,
    phoneNumber: clinicInfo.phoneNumber,
    departments: clinicInfo.departments,
    freeInputs: clinicInfo.freeInputs,
    reservationPeriod: clinicInfo.reservationPeriod,
    isAvailableSameDayReserve: clinicInfo.isAvailableSameDayReserve,
    sameDayReservePeriod: clinicInfo.sameDayReservePeriod,
    calendarStart: clinicInfo.calendarStart,
    email: clinicInfo.email,
    createdAt: clinicInfo.createdAt,
    clinicStatus: clinicInfo.clinicStatus,
    isInitialized: clinicInfo.isInitialized,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IClinicInformation => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      clinicName: data.clinicName as string,
      postalCode: data.postalCode as string,
      address: data.address as string,
      phoneNumber: data.phoneNumber as string,
      departments: data.departments as string,
      freeInputs: data.freeInputs as string,
      reservationPeriod: data.reservationPeriod as string,
      isAvailableSameDayReserve: data.isAvailableSameDayReserve as boolean,
      sameDayReservePeriod: data.sameDayReservePeriod as string,
      calendarStart: data.calendarStart as number,
      email: data.email as string,
      createdAt: data.createdAt as number,
      clinicStatus: data.clinicStatus as ClinicStatus,
      isInitialized: data.isInitialized as boolean,
    } as IClinicInformation;
  }
};