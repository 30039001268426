/**
 * 必須項目バリデーション
 * @param data ユーザー入力値
 * @return {string | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validateRequired = (data: string | undefined | null): string | undefined => {
  if (!data || data === '') {
    return '必須項目です。';
  }
  return undefined;
};

/**
 * メールアドレスのバリデーション(必須項目となります。)
 * @param email メールアドレス入力値
 * @return {string | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validateEmail = (email: string | undefined | null): string | undefined => {
  const requiredValid = validateRequired(email);
  if (requiredValid) {
    return requiredValid;
  }
  if (!email) {
    return '必須項目です。';
  }
  const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  if (regex.test(email)) {
    return undefined;
  }
  return '不正なメールアドレスです。';
};

/**
 * パスワードのバリデーション(必須項目となります。)
 * @param password パスワード入力値
 * @return {string | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validatePassword = (password: string | undefined | null): string | undefined => {
  const requiredValid = validateRequired(password);
  if (requiredValid) {
    return requiredValid;
  }
  if (!password) {
    return '必須項目です。';
  }
  const regex = /^([a-zA-Z0-9]{6,})$/;
  if (regex.test(password)) {
    return undefined;
  }
  return '半角英数字の6文字以上にする必要があります。';
};

/**
 * ひらがなのバリデーション
 * @param hiragana ひらがな
 * @param isRequired 必須項目かどうか
 * @return {string | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validateHiragana = (hiragana: string | undefined | null, isRequired?: boolean): string | undefined => {
  if (isRequired) {
    const requiredValid = validateRequired(hiragana);
    if (requiredValid) {
      return requiredValid;
    }
  } else if (!hiragana) {
    // 必須項目でない場合に空の場合
    return undefined;
  }
  const regex = /^[ぁ-んー\u3000]*$/;
  if (regex.test(hiragana!)) {
    return undefined;
  }
  return 'ひらがなを入力してください。';
};

/**
 * 電話番号のバリデーション(必須項目となります。)
 * @param phoneNumber 電話番号
 * @return {string | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validatePhoneNumber = (phoneNumber: string | undefined | null): string | undefined => {
  const requiredValid = validateRequired(phoneNumber);
  if (requiredValid) {
    return requiredValid;
  }
  if (!phoneNumber) {
    return '必須項目です。';
  }
  const phone = phoneNumber.replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, '');
  const regex = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/;
  if (regex.test(phone)) {
    return undefined;
  }
  return '不正な電話番号です。';
};

export const validateIsNumber = (text: string | undefined | null) : string | undefined => {
  const requiredValid = validateRequired(text);
  if (requiredValid) {
    return requiredValid;
  }
  if (!text) {
    return '必須項目です。';
  }
  const num = parseInt(text, 10)
  if (Number.isNaN(num)) {
    return '数値を入力してください。';
  }
  return undefined;
}