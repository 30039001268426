import {
  Button,
  Card,
  Stack,
  Typography
} from "@mui/material";
import { FC, useCallback } from "react";
import IClinicInformation from "../../interfaces/IClinicInformation";
import useClinicDetailDialogState from "./ClinicDetailDialog/useClinicDetailDialogState";
import ClinicStatusChip from "./ClinicStatusChip";
import useHomePageState from "./useHomePageState";
import { ClinicStatus } from "../../types/ClinicStatus";

type ClinicListItemProps = {
  clinicInfo: IClinicInformation,
  index: number,
}

const ClinicListItem: FC<ClinicListItemProps> = ({ clinicInfo, index }) => {
  const { onClickUpdateClinicStatus } = useHomePageState();
  const { openDialog } = useClinicDetailDialogState();
  
  const handleNextStatus = useCallback(() => {
    let nextStatus: ClinicStatus;
    if (clinicInfo.clinicStatus === 9) {
      if (clinicInfo.isInitialized) {
        nextStatus = 2;
      } else {
        nextStatus = 1;
      }
    } else {
      nextStatus = 9;
    }
    onClickUpdateClinicStatus(clinicInfo, nextStatus);
  }, [ clinicInfo, onClickUpdateClinicStatus ]);
  
  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4} p={1}>
        <Typography variant="subtitle1" color="inherit">
          {String(index).padStart(3, "0")}
        </Typography>
        <Stack spacing={0}>
          <Typography variant="h6" color="inherit">
            {clinicInfo.clinicName}
          </Typography>
          <Typography variant="subtitle1" color="inherit">
            {clinicInfo.email}
          </Typography>
        </Stack>
        <ClinicStatusChip clinicStatus={clinicInfo.clinicStatus} />
        <Stack spacing="6px">
          <Button variant="contained" color="inherit" onClick={() => openDialog(clinicInfo)}>
            詳細
          </Button>
          <Button variant="contained" color="error"
                  onClick={handleNextStatus}>
            {clinicInfo.clinicStatus === 9 ? "元に戻す" : "削除"}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ClinicListItem;