import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Table, TableBody, TableCell, TableRow, Typography
} from "@mui/material";
import useClinicDetailDialogState from "./useClinicDetailDialogState";

const ClinicDetailDialog = () => {
  const { state, closeDialog } = useClinicDetailDialogState();
  if (!state.clinicInfo) {
    return null;
  }
  
  return <Dialog open={state.isOpen} onClose={() => closeDialog()}>
    <DialogTitle fontWeight="bold">
      クリニック詳細情報
    </DialogTitle>
    <DialogContent dividers>
      <Grid container justifyContent="start" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <Table sx={{ width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                  クリニックID
                </TableCell>
                <TableCell sx={{ width: "60%" }} align="right">
                  {state.clinicInfo.id}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                  クリニック名
                </TableCell>
                <TableCell sx={{ width: "60%" }} align="right">
                  {state.clinicInfo.clinicName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                  診療科目
                </TableCell>
                <TableCell sx={{ width: "60%" }} align="right">
                  {state.clinicInfo.departments}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                  郵便番号
                </TableCell>
                <TableCell sx={{ width: "60%" }} align="right">
                  {state.clinicInfo.postalCode}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                  住所
                </TableCell>
                <TableCell sx={{ width: "60%" }} align="right">
                  {state.clinicInfo.address}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                  電話番号
                </TableCell>
                <TableCell sx={{ width: "60%" }} align="right">
                  {state.clinicInfo.phoneNumber}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                  自由入力欄
                </TableCell>
                <TableCell sx={{ width: "60%" }} align="right"
                           style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}>
                  {state.clinicInfo.freeInputs}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={() => closeDialog()}>
        閉じる
      </Button>
    </DialogActions>
  </Dialog>;
};

export default ClinicDetailDialog;