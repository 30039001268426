import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export type InputFormProps = {
  label: string;
  required?: boolean;
  onChange?: (txt: string) => void;
  error?: string | undefined;
  multiline?: boolean;
  minLines?: number | undefined;
  maxLines?: number | undefined;
  password?: boolean;
  type?: string;
  value?: string;
};

const InputForm: FC<InputFormProps> = ({
  label,
  required,
  onChange,
  error,
  multiline,
  minLines,
  maxLines,
  password,
  type,
  value,
}) => {
  const [ passwordShowState, setPasswordShowState ] = useState(!password);
  
  const handleClickShowPassword = () => {
    setPasswordShowState((prev) => !prev);
  };
  
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  
  const currentType = useMemo(() => {
    if (type) {
      return type;
    }
    return passwordShowState ? 'text' : 'password';
  }, [ passwordShowState, type ]);
  
  return <FormControl fullWidth error={!!error}>
    <InputLabel htmlFor="filled-adornment-amount">{label}</InputLabel>
    <OutlinedInput
      required={required}
      id="outlined-adornment-amount"
      type={currentType}
      label={label}
      sx={{
        borderRadius: '24px'
      }}
      multiline={multiline}
      minRows={minLines}
      maxRows={maxLines}
      endAdornment={password ? <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {passwordShowState ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment> : null}
      value={value}
      onChange={(e) =>
        onChange ? onChange(e.target.value ?? '') : undefined
      }
    />
    <FormHelperText component="div" error={!!error}>
      <Typography
        variant="caption"
        style={{ fontSize: '11px', color: error ? 'red' : 'grey' }}
      >
        {error}
      </Typography>
    </FormHelperText>
  </FormControl>
};

InputForm.defaultProps = {
  required: false,
  onChange: undefined,
  error: undefined,
  multiline: false,
  minLines: undefined,
  maxLines: undefined,
  password: false,
  type: undefined,
  value: undefined,
};

export default InputForm;