import { atom } from "recoil";
import { ResetPasswordErrors } from "../../../interfaces/IAuthInformation";

export type ResetPasswordPageState = {
  email: string;
  errors: ResetPasswordErrors | undefined;
};

const initialState: ResetPasswordPageState = {
  email: "",
  errors: undefined
};

const resetPasswordPageStateAtom = atom({
  key: "ResetPasswordPageState",
  default: initialState
});

export default resetPasswordPageStateAtom;