import { validateEmail, validatePassword } from "../utils/validator";

export type ISignIn = {
  email: string,
  password: string,
};

export type SignInErrors = {
  email: string | undefined;
  password: string | undefined;
};
export const validateSignIn = (input: ISignIn): SignInErrors | undefined => {
  const errors: SignInErrors = {
    email: undefined,
    password: undefined
  };
  errors.email = validateEmail(input.email);
  errors.password = validatePassword(input.password);
  if (Object.values(errors).findIndex((element) => typeof element === "string") !== -1) {
    return errors;
  }
  return undefined;
};

export type IResetPassword = {
  email: string,
}

export type ResetPasswordErrors = {
  email: string | undefined;
}
export const validateResetPassword = (input: IResetPassword): ResetPasswordErrors | undefined => {
  const errors: ResetPasswordErrors = {
    email: undefined,
  };
  errors.email = validateEmail(input.email);
  if (Object.values(errors).findIndex((element) => typeof element === "string") !== -1) {
    return errors;
  }
  return undefined;
};