import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { homePagePath, loginPagePath, registerClinicPagePath, resetPasswordPath } from "./layout/urls";
import LoginPage from "./features/LoginPage/LoginPage";
import HomePage from "./features/HomePage/HomePage";
import ResetPassword from "./features/ResetPassword/ResetPassword";
import RegisterClinicPage from "./features/RegisterClinicPage/RegisterClinicPage";
import AuthGuard from "./features/PageBase/AuthGuard";

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path={loginPagePath} element={<LoginPage />} />
        <Route path={resetPasswordPath} element={<ResetPassword />} />
        <Route path={homePagePath} element={<AuthGuard><HomePage /></AuthGuard>} />
        <Route path={registerClinicPagePath} element={<AuthGuard><RegisterClinicPage /></AuthGuard>} />
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
