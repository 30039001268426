import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import InputForm from "../../components/InputForm/InputForm";
import useResetPasswordState from "./useResetPasswordState";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { state, onChangeEmail, onClickSendResetPasswordEmail, isLoading } = useResetPasswordState();
  
  return <Container maxWidth="xs">
    <Stack direction="column" justifyContent="center" py={6} spacing={4}>
      <div style={{ textAlign: "start" }}>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </Button>
      </div>
      <Typography variant="h5">
        パスワードリセット
      </Typography>
      <Typography variant="body1">
        CSI管理システムで使用しているメールアドレスを入力してください。<br />
        そのアドレス宛にパスワードリセットを行う為のメールを送付します。
      </Typography>
      <Box />
      <InputForm label="メールアドレス"
                 onChange={onChangeEmail}
                 error={state.errors?.email}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        sx={{
          borderRadius: "24px",
          color: "#FFFFFF",
          elevation: 3,
          py: "8px"
        }}
        loadingIndicator="実行中..."
        loading={isLoading}
        onClick={onClickSendResetPasswordEmail}
      >
        送信する
      </LoadingButton>
    </Stack>
  </Container>;
};

export default ResetPassword;