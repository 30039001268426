import { Link } from "react-router-dom";
import { Container, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import InputForm from "../../components/InputForm/InputForm";
import { resetPasswordPath } from "../../layout/urls";
import useLoginPageState from "./useLoginPageState";

const LoginPage = () => {
  const { state, onChangeEmail, onChangePassword, onClickLogin, isLoading } = useLoginPageState();
  
  return <Container maxWidth="xs">
    <Stack direction="column" justifyContent="center" alignItems="center" py={6} spacing={4}>
      <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
        <Typography variant="h5">coMect</Typography>
        <Typography variant="caption">～ CSI管理システム ～</Typography>
      </Stack>
      <InputForm label="メールアドレス" required type="email"
                 onChange={onChangeEmail}
                 value={state.email}
                 error={state.errors?.email}
      />
      <InputForm label="パスワード" required password
                 onChange={onChangePassword}
                 value={state.password}
                 error={state.errors?.password}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        sx={{
          borderRadius: "24px",
          color: "#FFFFFF",
          elevation: 3,
          py: "8px"
        }}
        loadingIndicator="実行中..."
        loading={isLoading}
        onClick={onClickLogin}
      >
        ログイン
      </LoadingButton>
      <Typography
        component={Link}
        to={resetPasswordPath}
        variant="caption"
      >
        パスワードを忘れた方はこちら
      </Typography>
    </Stack>
  </Container>;
};

export default LoginPage;