import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CreateIcon from "@mui/icons-material/Create";
import LockIcon from "@mui/icons-material/Lock";
import { ReactNode } from "react";
import { useNavigate } from "react-router";
import { homePagePath, registerClinicPagePath, resetPasswordPath } from "../../layout/urls";

type MenuItemType = {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
};

const NavDrawer = () => {
  const drawerWidth = 240;
  const navigate = useNavigate();
  
  /**
   * ドロワー表示メニュー項目
   */
  const menuItems: MenuItemType[] = [
    {
      label: "ホーム",
      icon: <HomeIcon />,
      onClick: () => {
        navigate(homePagePath);
      }
    },
    {
      label: "クリニック登録",
      icon: <CreateIcon />,
      onClick: () => {
        navigate(registerClinicPagePath);
      }
    },
    {
      label: "パスワード設定",
      icon: <LockIcon />,
      onClick: () => {
        navigate(resetPasswordPath);
      }
    }
  ];
  
  return <Drawer
    variant="permanent"
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
    }}>
    <Toolbar />
    <Box sx={{ overflow: "auto" }}>
      <List>
        {menuItems.map((item) => <ListItem key={item.label}>
          <ListItemButton onClick={item.onClick}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>)
        }
      </List>
    </Box>
  </Drawer>;
};

export default NavDrawer;