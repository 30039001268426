import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useAuthSignOut } from "@react-query-firebase/auth";
import { useQuery } from "react-query";
import { LoadingButton } from "@mui/lab";
import MenuIcon from "@mui/icons-material/Menu";
import { auth } from "../../firebase";
import useNavDrawerMobileState from "../NavDrawer/useNavDrawerMobileState";

export type AppBarProps = {
  title: string;
};

const AdminAppBar: FC<AppBarProps> = ({ title }) => {
  const logoutMutation = useAuthSignOut(auth);
  
  const signOut = useCallback(async () => {
    await logoutMutation.mutateAsync();
    window.location.reload();
  }, [ logoutMutation ]);
  const { isLoading, isRefetching, refetch } = useQuery("signOut", () => signOut(), {
    enabled: false,
    suspense: false
  });
  const onClickLogOut = useCallback(() => {
    void refetch();
  }, [ refetch ]);
  const { handleToggle } = useNavDrawerMobileState();
  
  return <AppBar
    color="default"
    position="fixed"
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      textAlign: {
        "xs": "center",
        "md": "start"
      }
    }}
    elevation={1}
  >
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleToggle}
        sx={{
          display: {
            md: "none"
          }
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box m="auto">
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{
            display: {
              "xs": "none",
              "md": "block"
            }
          }}
        >
          {title}
        </Typography>
      </Box>
      <LoadingButton loading={isLoading || isRefetching} onClick={onClickLogOut}
                     loadingIndicator="Loading...">
        <Typography variant="button">ログアウト</Typography>
      </LoadingButton>
    </Toolbar>
  </AppBar>;
};

export default AdminAppBar;