import { useAuthUser } from "@react-query-firebase/auth";
import { ReactNode, FC } from "react";
import { Navigate } from "react-router";
import { auth } from "../../firebase";
import { homePagePath, loginPagePath } from "../../layout/urls";

type AuthGuardProps = {
  children: ReactNode;
  isNotLoggedInOnly?: boolean;
};

const AuthGuardContent: FC<AuthGuardProps> = ({ children, isNotLoggedInOnly }) => {
  const user = useAuthUser([ "user" ], auth);
  
  // ログインしていない状態でしか表示できないページ
  if (isNotLoggedInOnly) {
    if (user.data) {
      return <Navigate to={homePagePath} />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  
  if (!user.data) {
    // TODO: ログインしてくださいのアラート表示
    return <Navigate to={loginPagePath} />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

AuthGuardContent.defaultProps = {
  isNotLoggedInOnly: false
};

const AuthGuard: FC<AuthGuardProps> = ({ isNotLoggedInOnly, children }) =>
  <AuthGuardContent isNotLoggedInOnly={isNotLoggedInOnly}>{children}</AuthGuardContent>;

AuthGuard.defaultProps = {
  isNotLoggedInOnly: false
};

export default AuthGuard;
