import { atom } from "recoil";
import IClinicInformation from "../../../../interfaces/IClinicInformation";

export type ClinicDetailDialogState = {
  isOpen: boolean;
  clinicInfo: IClinicInformation | undefined;
};

const initialState: ClinicDetailDialogState = {
  isOpen: false,
  clinicInfo: undefined,
};

const clinicDetailDialogStateAtom = atom({
  key: "ClinicDetailDialogState",
  default: initialState
});

export default clinicDetailDialogStateAtom;